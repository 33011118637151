import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import SEO from '../components/SEO/SEO'
import Footer from '../components/Footer'
import website from '../../config/website'
import SliceZone from '../components/SliceZone'

export const PageTemplate = ({ data }) => {
	if (!data) return null

	const pageContent = data.prismicPage
	const page = pageContent.data || {}

	const { lang, type, url } = pageContent
	const activeDoc = {
		lang,
		type,
		url,
	}
	const topMenu = data.prismicTopMenu || {}

	return (
		<>
			<SEO
				title={`${pageContent.data.display_title.text} | ${website.titleAlt}`}
				pathname={typeof window === 'undefined' ? '' : location.pathname}
				desc={pageContent.data.social_description_override || data.description}
				banner={pageContent.data.social_image_override.url}
				node={pageContent}
			/>
			<SliceZone slices={page.body} />
		</>
	)
}

export const query = graphql`
	query pageQuery($uid: String, $lang: String) {
		prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
			url
			uid
			type
			id
			lang
			alternate_languages {
				id
				type
				lang
				uid
				url
			}
			data {
				display_title {
					text
				}
				social_description_override
				social_image_override {
					url
				}
				body {
					... on PrismicPageBodyInfoWithImage {
						id
						slice_label
						slice_type
						primary {
							featured_image {
								alt
								url
								fixed(imgixParams: { w: 400, h: 400 }) {
									src
								}
							}
							text {
								html
								raw
								text
							}
							text_position
						}
					}
					... on PrismicPageBodyTextInfo {
						id
						slice_label
						slice_type
						primary {
							title {
								html
								raw
								text
							}
							left_column_text {
								html
								raw
								text
							}
							right_column_text {
								html
								raw
								text
							}
						}
					}
					... on PrismicPageBodyGeneralContent {
						id
						slice_label
						slice_type
						primary {
							theme_colour
							content {
								html
								raw
								text
							}
						}
					}
					... on PrismicPageBodyBlockquote {
						id
						slice_label
						slice_type
						primary {
							text {
								html
								raw
								text
							}
							cite {
								html
								raw
								text
							}
							image {
								alt
								url
								fluid(
									imgixParams: {
										maxWidth: 300
										w: 300
										width: 300
										fit: "crop"
										auto: "format"
									}
								) {
									src
									srcSet
								}
							}
						}
					}
					... on PrismicPageBodyHeroImageWithText {
						id
						slice_label
						slice_type
						primary {
							image {
								alt
								url
								fluid(
									imgixParams: {
										maxWidth: 300
										w: 300
										width: 300
										fit: "crop"
										auto: "format"
									}
								) {
									src
									srcSet
								}
							}
							content {
								html
								raw
								text
							}
						}
					}
					... on PrismicPageBodyKeyTextGrid {
						id
						slice_label
						slice_type
						items {
							content {
								html
								raw
								text
							}
						}
					}
					... on PrismicPageBodyTestimonial {
						id
						slice_label
						slice_type
						primary {
							title {
								text
							}
						}
						items {
							cite {
								raw
							}
							person_name {
								raw
							}
							testimonial {
								raw
							}
						}
					}
					... on PrismicPageBodyPricingTable {
						id
						slice_label
						slice_type
						primary {
							title {
								raw
							}
						}
						items {
							pricing_image {
								alt
								url
								fixed(imgixParams: { w: 400, h: 400 }) {
									src
								}
							}
							price_quote {
								raw
								text
							}
							price_title {
								raw
								text
							}
							price_sub_title {
								raw
							}
							price_description {
								raw
							}
							price_included_features {
								raw
							}
							price {
								raw
								text
							}
							link {
								url
								target
							}
						}
					}
				}
			}
		}
		prismicTopMenu(lang: { eq: $lang }) {
			...TopMenuFragment
		}
	}
`

export default withPreview(PageTemplate)
